import React, { useState, useEffect } from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import styled from "styled-components";
import { sprintf } from "sprintf-js";

const Page = () => (
  <Layout>
    <SEO title="Countdown Timer" />
    <CountdownTimer />
  </Layout>
);

const useTimedRerender = (framesPerSecond: number, enabled: boolean) => {
  const [, setRenderCount] = useState(0);
  useEffect(() => {
    let intervalHandle = null as number;
    if (enabled) {
      intervalHandle = setInterval(() => {
        setRenderCount(currentRenderCount => currentRenderCount + 1);
      }, (1.0 / framesPerSecond) * 1000);
    }

    return () => {
      if (intervalHandle != null) {
        clearInterval(intervalHandle);
      }
    };
  }, [enabled]);
};

const timeFromMilliseconds = (millis: number) => {
  const minutes = Math.floor(millis / 1000 / 60)
    .toString()
    .padStart(2, "0");
  const seconds = sprintf("%05.2f", (millis / 1000) % 60);
  return [minutes, seconds];
};

const beep = () => {
  const audio = new Audio("../sounds/beep.wav");
  audio.play();
};

const CountdownTimer = () => {
  const [running, setRunning] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [previouslyElapsedMillis, setPreviouslyElapsedMillis] = useState(0);
  const [requestedMinutes, setRequestedMinutes] = useState("");
  const [requestedSeconds, setRequestedSeconds] = useState("");

  useTimedRerender(30, running);

  const elapsedMillis = running
    ? previouslyElapsedMillis + Date.now() - startTime
    : previouslyElapsedMillis;

  const requestedMinutesMillis = Number(requestedMinutes) * 60 * 1000;
  const requestedSecondsMillis = Number(requestedSeconds) * 1000;

  const requestedMillis = requestedMinutesMillis + requestedSecondsMillis;
  const remainingMillis = requestedMillis - elapsedMillis;

  const [minutes, seconds] = timeFromMilliseconds(remainingMillis);

  const onStartClick = () => {
    setRunning(true);
    setStartTime(Date.now());
  };
  const onPauseClick = () => {
    setRunning(false);
    setPreviouslyElapsedMillis(
      Date.now() - startTime + previouslyElapsedMillis
    );
  };
  const onResetClick = () => {
    setRunning(false);
    setStartTime(null);
    setPreviouslyElapsedMillis(0);
  };

  if (remainingMillis <= 0 && running) {
    setRunning(false);
    setPreviouslyElapsedMillis(requestedMillis);
    beep();
  }

  return (
    <React.Fragment>
      <p>Countdown Timer!</p>
      <TextInput
        label="Minutes"
        value={requestedMinutes}
        onChange={event => setRequestedMinutes(event.target.value)}
      />
      <TextInput
        label="Seconds"
        value={requestedSeconds}
        onChange={event => setRequestedSeconds(event.target.value)}
      />
      <CountdownDisplay>
        {minutes}:{seconds}
      </CountdownDisplay>
      <StartPauseButton
        running={running}
        onStartClick={onStartClick}
        onPauseClick={onPauseClick}
      />{" "}
      <StyledResetButton onClick={onResetClick}>Reset</StyledResetButton>
    </React.Fragment>
  );
};

interface TextInputProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextInput = ({ label, value, onChange }: TextInputProps) => {
  return (
    <label>
      <div>{label}</div>
      <input type="text" value={value} onChange={onChange} />
    </label>
  );
};

interface StartPauseButtonProps {
  running: boolean;
  onStartClick: () => void;
  onPauseClick: () => void;
}

const StartPauseButton = ({
  running,
  onStartClick,
  onPauseClick,
}: StartPauseButtonProps) => {
  return (
    <StyledStartPauseButton onClick={running ? onPauseClick : onStartClick}>
      {running ? "Pause" : "Start"}
    </StyledStartPauseButton>
  );
};

const CountdownDisplay = styled.div`
  font-size: 80px;
  line-height: normal;
`;

const StyledStartPauseButton = styled.button`
  background: #73ad21;
  color: white;
`;
const StyledResetButton = styled.button`
  background: #2e8b57;
  color: white;
`;
export default Page;
